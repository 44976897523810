import type { Photo } from '$lib/graphql/types'
import type { HotelPropertyFieldsFragment } from '$lib/queries/fragments/generated/HotelPropertyFields'
import { uniqBy } from 'lodash-es'
import cdnImageURL, { cdnSrcSetHiDPI } from 'src/utils/cdn'

export type HighlightPhoto = {
  id: string
  src: string
  srcset: string
  alt: string
  width: number
  height?: number
}

const excludedCategories = [/badge/] // Filter out Covid-Safe badges

export function selectHighlightsForHotel(hotel: HotelPropertyFieldsFragment): HighlightPhoto[] {
  let allPhotos = [hotel?.heroPhoto, ...filterBadges(hotel?.photos?.edges?.map((e) => e.node))]

  if (allPhotos?.length < 4) {
    allPhotos = [
      ...allPhotos,
      ...hotel.rooms?.edges?.flatMap((r) => r.node?.photos?.edges?.map((p) => p.node)),
    ]
  }
  const photosSelection = uniqBy(allPhotos, 'id')
  return selectHighlights(photosSelection)
}

// selectHighlights returns a list of photos to use for highlights.
export function selectHighlights(photosSelection: Photo[]): HighlightPhoto[] {
  // Contains an array of entries for each highlight image, representing width and height (optional).
  const sizes = [[590], [350, 256], [350, 256], [590]]

  const photos: HighlightPhoto[] = []
  sizes.forEach(([width, height], index) => {
    const photo = photosSelection[index]
    if (photo?.url)
      photos.push({
        id: photo?.id,
        src: cdnImageURL({ src: photo?.url, width, height }),
        srcset: cdnSrcSetHiDPI(photo?.url, width, height),
        alt: photo?.category ?? '',
        width,
        height,
      })
  })

  return photos
}

export function filterBadges<T extends Photo>(photos: Pick<T, 'category'>[]): T[] {
  return photos.filter(
    (photo) => !excludedCategories.some((cat) => cat.test(photo?.category)),
  ) as T[]
}
